<template>
  <!-- begin::kb-header -->
  <header class="kb-header" id="kb-header">
    <div class="header-component">
      <!-- header-title -->
      <div class="header-column header-title">
        연수원 개인 신청 내역
      </div>
      <!-- //header-title -->
      <!-- header-util -->
      <div class="header-column header-util left">
        <div class="util util-back">
          <a href="javascript:" class="util-actions util-actions-back" @click="goBack">
            <i class="icon-history-back"></i>
          </a>
        </div>
      </div>
      <!-- //header-util -->
    </div>
  </header>
  <!-- end::kb-header -->

  <!-- begin::kb-main -->
  <main class="kb-main" id="kb-facility">

    <!-- main-content -->
    <div class="main-content main-component">
      <div class="view-contents">
        <section class="content-section">
          <div class="section-content">
            <div class="content-item bd-clear">
              <div class="title">신청자 정보</div>
              <div class="desc">
                <p>{{ session.deptNm }} ・ {{ session.jbgdNm }} ・ {{ session.lrnerNm }}</p>
              </div>
            </div>
            <div class="content-item">
              <div class="title">연수원</div>
              <div class="desc">
                <p>{{ trnctAplyIns.trnctMstSn == '1' ? '속초연수원' : '대천연수원'}}</p>
              </div>
            </div>
            <div class="content-item">
              <div class="title">입소일</div>
              <div class="desc">
<!--                <p @click="openSelectPeriod">{{ trnctAplyIns.rsvtAplyDd }} ({{ getDayOfWeek(trnctAplyIns.rsvtAplyDd) }})</p>-->
                <div @click="openSelectPeriod" style="height: 50px; padding: 16px; border: 1px solid #e8e8e8 !important; border-radius: 8px; font: normal 14px/22px var(--kb-font-KBFGTextB);">
                  {{ trnctAplyIns.rsvtAplyDd }} ({{ getDayOfWeek(trnctAplyIns.rsvtAplyDd) }})
                </div>
              </div>
            </div>
          </div>
          <div class="section-content">
            <div class="content-item">
              <div class="title">숙식형태</div>
              <div class="chk-cont">
                <label for="chk_1" class="item-agree" v-if="trnctAplyIns.trnctMstSn == '2'">
                  원룸
                  <div class="kb-form-check">
                    <input type="radio" id="chk_1" name="chk_cancel" class="kb-form-check-input" value="OR" v-model="trnctAplyIns.roomTy" disabled>
                  </div>
                </label>

                <label for="chk_2" class="item-agree">
                  투룸
                  <div class="kb-form-check">
                    <input type="radio" id="chk_2"  name="chk_cancel" class="kb-form-check-input" value="TR" v-model="trnctAplyIns.roomTy" disabled>
                  </div>
                </label>

                <label for="chk_3" class="item-agree">
                  안식년(투룸)
                  <div class="kb-form-check">
                    <input type="radio" id="chk_3" name="chk_cancel" class="kb-form-check-input" value="LTR" v-model="trnctAplyIns.roomTy" disabled>
                  </div>
                </label>
              </div>
            </div>
            <div class="content-item">
              <div class="title">이용기간 선택</div>
              <div class="desc">

                <div class="kb-form-dropdown" :class="{'is-active' : usePeridToggle}" data-offset="bottom-left">
                  <button class="kb-form-dropdown-btn" @click="usePeridToggle=!usePeridToggle"><span class="text">{{ trnctAplyIns.usePeriodTxt }}</span><i class="icon-arrow"></i></button>

                  <div class="kb-form-dropdown-options" style="width: 358px;">
                    <div class="dropdown-option">
                      <ul class="dropdown-option-list">
                        <li class="dropdown-option-item" v-for="item in usePeridOption" :key="item.value">
                          <a href="javascript:" class="dropdown-option-link" @click="selectUsePeriod(item)">
                            <span class="dropdown-option-text">{{ item.label }}</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <!-- content-section:신청 정보 -->
        <article class="content-section">
          <header class="section-header">
            <h3 class="title">신청 정보</h3>
          </header>
          <div class="kb-form-fields kb-form-fields-v2 kb-form-fields-divider">
            <!-- kb-form-field:비상 연락처 -->
            <div class="kb-form-field field-first">
              <div class="kb-form-row">
                <label for="" class="kb-form-label"><span class="kb-form-label-text">비상 연락처</span></label>
              </div>
              <div class="kb-form-row">
                <input type="tel" class="kb-form-control" placeholder="'-' 없이 숫자만으로 비상연락처를 입력하세요.'" v-model="trnctAplyIns.emerCt"/>
              </div>
            </div>
            <!-- kb-form-field:차량 번호 -->
            <div class="kb-form-field field-first" v-if="trnctAplyIns.prsnBrcYn == 'Y'">
              <div class="kb-form-row">
                <label for="" class="kb-form-label"><span class="kb-form-label-text">차량 번호</span></label>
              </div>
              <div class="kb-form-row">
                <input type="text" class="kb-form-control" placeholder="차량번호를 입력하세요." v-model="trnctAplyIns.carNo"/>
              </div>
            </div>
            <!-- kb-form-field:서무직원 번호 -->
            <div class="kb-form-field field-first" v-if="trnctAplyIns.prsnBrcYn == 'N'">
              <div class="kb-form-row">
                <label for="" class="kb-form-label"><span class="kb-form-label-text">서무직원 번호</span></label>
              </div>
              <div class="kb-form-row">
                <input type="text" class="kb-form-control" placeholder="서무직원번호를 입력하세요." v-model="trnctAplyIns.mblTelno"/>
              </div>
            </div>
            <!-- kb-form-field:신청숙실수 -->
            <div class="kb-form-field field-first" v-if="trnctAplyIns.prsnBrcYn == 'N'">
              <div class="kb-form-row">
                <label for="" class="kb-form-label"><span class="kb-form-label-text">신청숙실수</span></label>
              </div>
              <div class="kb-form-row">
                <input type="number" class="kb-form-control" placeholder="신청 숙실수." v-model="trnctAplyIns.useRoomCnt"/>
              </div>
            </div>

            <div class="kb-form-field" v-if="trnctAplyIns.prsnBrcYn == 'N'">
              <div class="kb-form-row">
                <label for="" class="kb-form-label"><span class="kb-form-label-text">입소인원(남자)</span></label>
              </div>
              <div class="kb-form-row">
                <input v-model="trnctAplyIns.mlePeopl" type="text" class="kb-form-control" placeholder=""/>
                <div class="kb-form-side">
                  명
                </div>
              </div>
            </div>

            <div class="kb-form-field" v-if="trnctAplyIns.prsnBrcYn == 'N'">
              <div class="kb-form-row">
                <label for="" class="kb-form-label"><span class="kb-form-label-text">입소인원(여자)</span></label>
              </div>
              <div class="kb-form-row">
                <input v-model="trnctAplyIns.mlePeopl" type="text" class="kb-form-control" placeholder=""/>
                <div class="kb-form-side">
                  명
                </div>
              </div>
            </div>

            <!-- kb-form-field:입소자 1 -->
            <div class="kb-form-field" v-if="trnctAplyIns.prsnBrcYn == 'Y'">
              <div class="kb-form-row">
                <label for="" class="kb-form-label"><span class="kb-form-label-text">입소자 1</span></label>
              </div>
              <div class="kb-form-row">
                <input v-model="trnctAplyIns.aplyDtl[0].cpnnNm" type="text" class="kb-form-control" placeholder="이름을 입력하세요" disabled/>
                <div class="kb-form-select left-margin" >
                  <select disabled v-model="trnctAplyIns.aplyDtl[0].aplcntRel" >
                    <option value="" disabled >관계 선택</option>
                    <option value="A1" selected>본인</option>
                  </select>
                </div>
              </div>
            </div>
            <!-- kb-form-field:입소자 2 -->
            <div class="kb-form-field" v-if="trnctAplyIns.prsnBrcYn == 'Y'">
              <div class="kb-form-row">
                <label for="" class="kb-form-label"><span class="kb-form-label-text">입소자 2</span></label>
                <button class="kb-btn-side-text" @click="deleteFamily(1)"><span class="font-body12B text-red">삭제</span></button>
              </div>
              <div class="kb-form-row">
                <input v-model="trnctAplyIns.aplyDtl[1].cpnnNm" type="text" class="kb-form-control" placeholder="이름을 입력하세요" />
                <div class="kb-form-select left-margin">
                  <select required v-model="trnctAplyIns.aplyDtl[1].aplcntRel">
                    <option v-for="(item,index) in familyList" :key="index" :value="item.code">
                      {{ item.label }}
                    </option>
                    <!--
                    <li class="dropdown-option-item" v-for="item in familyList" :key="item.code">
                      <a href="javascript:" class="dropdown-option-link" @click="selectAply(0,item.code)">
                        <span class="dropdown-option-text">{{ item.label }}</span>
                      </a>
                    </li>
                    -->
                  </select>
                </div>
              </div>
            </div>
            <!-- kb-form-field:입소자 3 -->
            <div class="kb-form-field" v-if="trnctAplyIns.prsnBrcYn == 'Y'">
              <div class="kb-form-row">
                <label for="" class="kb-form-label"><span class="kb-form-label-text">입소자 3</span></label>
                <button class="kb-btn-side-text"><span class="font-body12B text-red">삭제</span></button>
              </div>
              <div class="kb-form-row">
                <input v-model="trnctAplyIns.aplyDtl[2].cpnnNm" type="text" class="kb-form-control" placeholder="이름을 입력하세요" />
                <div class="kb-form-select left-margin">
                  <select required v-model="trnctAplyIns.aplyDtl[2].aplcntRel">
                    <option v-for="(item,index) in familyList" :key="index" :value="item.code">
                      {{ item.label }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <!-- kb-form-field:입소자 4 -->
            <div class="kb-form-field" v-if="trnctAplyIns.prsnBrcYn == 'Y'">
              <div class="kb-form-row">
                <label for="" class="kb-form-label"><span class="kb-form-label-text">입소자 4</span></label>
                <button class="kb-btn-side-text"><span class="font-body12B text-red">삭제</span></button>
              </div>
              <div class="kb-form-row">
                <input v-model="trnctAplyIns.aplyDtl[3].cpnnNm" type="text" class="kb-form-control" placeholder="이름을 입력하세요" />
                <div class="kb-form-select left-margin">
                  <select required v-model="trnctAplyIns.aplyDtl[3].aplcntRel">
                    <option v-for="(item,index) in familyList" :key="index" :value="item.code">
                      {{ item.label }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <!-- kb-form-field:입소자 5 -->
            <div class="kb-form-field" v-if="trnctAplyIns.prsnBrcYn == 'Y'">
              <div class="kb-form-row">
                <label for="" class="kb-form-label"><span class="kb-form-label-text">입소자 5</span></label>
                <button class="kb-btn-side-text"><span class="font-body12B text-red">삭제</span></button>
              </div>
              <div class="kb-form-row">
                <input v-model="trnctAplyIns.aplyDtl[4].cpnnNm" type="text" class="kb-form-control" placeholder="이름을 입력하세요" />
                <div class="kb-form-select left-margin">
                  <select  required v-model="trnctAplyIns.aplyDtl[4].aplcntRel">
                    <option v-for="(item,index) in familyList" :key="index" :value="item.code">
                      {{ item.label }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <!-- kb-form-field:이용인원 -->
            <div class="kb-form-field">
              <div class="kb-form-row">
                <label for="" class="kb-form-label"><span class="kb-form-label-text">이용인원</span></label>
              </div>
              <div class="kb-form-row">
                <span></span>
                <div class="kb-form-side">{{ trnctAplyIns.aplyPeopl}} 명</div>
              </div>
            </div>

            <!-- kb-form-field:특이사항 -->
            <div class="kb-form-field">
              <div class="kb-form-row">
                <label for="" class="kb-form-label"><span class="kb-form-label-text">특이사항</span></label>
              </div>
              <div class="kb-form-row">
                <textarea  v-model="trnctAplyIns.dmndCn" class="kb-form-control" placeholder="특이사항이 있을 경우 작성해주세요."></textarea>
              </div>
            </div>
            <!-- //kb-form-field -->
          </div>
        </article>
      </div>
      <div class="page-buttons"><button class="kb-btn kb-btn-primary" @click="fnBeforeSave">수정</button></div>
    </div>
    <!-- //main-content -->
  </main>
  <!-- end::kb-main -->
</template>
<script>
import {computed, onMounted, reactive, ref, watch,} from 'vue';
import {useStore} from "vuex";
import {isSuccess, lengthCheck, timestampToDateFormat,isTelFormat,isHpTelFormat} from "@/assets/js/util";

import {
  ACT_GET_TRNCT_OPER_YMD_CHECK,
  ACT_UPDATE_TRNCT_APLY
} from "@/store/modules/trnct/trnct";
import {useAlert} from "@/assets/js/modules/common/alert";
import {useRouter} from "vue-router";

export default {
  name: 'DailyMobileApplyDetail',
  components: {

  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const {showMessage,showConfirm} = useAlert();

    const session = computed(() => store.state.auth.session);
    const storeTrnctAplyIns = computed(() => store.state.trnct.mobileApplyInfo);

    const isPersonal = ref(true);
    const reserveVenue = ref('연수원');
    const trnctAgree = ref(false);
    const usePeridOption = ref([]);
    const usePeridToggle = ref(false);

    const familyList = ref([]);
    familyList.value.push({ label : "관계 선택", code : null});
    familyList.value.push({ label : "본인", code : "A1"});
    familyList.value.push({ label : "배우자", code : "A2"});
    familyList.value.push({ label : "자녀", code : "A3"});
    familyList.value.push({ label : "본인(부)", code : "A4"});
    familyList.value.push({ label : "본인(모)", code : "A5"});
    familyList.value.push({ label : "배우자(부)", code : "A6"});
    familyList.value.push({ label : "배우자(모)", code : "A7"});
    familyList.value.push({ label : "조부", code : "A8"});
    familyList.value.push({ label : "조모", code : "A9"});

    console.log(store.state.trnct.mobileApplyInfo);

    const trnctAplyIns = reactive({
      prsnBrcYn : 'Y',
      room: store.state.trnct.mobileApplyInfo.room,
      roomTy: store.state.trnct.mobileApplyInfo.room,
      stbyYn: store.state.trnct.mobileApplyInfo.stbyYn,
      usePeriodTxt: store.state.trnct.mobileApplyInfo.usePeriodTxt,
      usePeriodTxt2: store.state.trnct.mobileApplyInfo.usePeriodTxt2,
      usePerid: store.state.trnct.mobileApplyInfo.usePerid,
      tempUsePerid: store.state.trnct.mobileApplyInfo.usePerid,
      rsvtAplyDd: store.state.trnct.mobileApplyInfo.rsvtAplyDd,
      aplcntId : store.state.auth.session.lrnerId, //신청자ID
      aplcntNm : store.state.auth.session.lrnerNm, //신청자명
      aplcntDeptCd : store.state.auth.session.deptCd, //신청자 부서코드
      aplcntDeptNm : store.state.auth.session.deptNm, //신장자 부서명
      aplcntJbgd : store.state.auth.session.jbgdCd, //신청자직급
      aplyPeopl : store.state.trnct.mobileApplyInfo.aplyPeopl,  //신청인원
      mlePeopl : store.state.trnct.mobileApplyInfo.mlePeopl,   //남성인원
      femPeopl : store.state.trnct.mobileApplyInfo.femPeopl,   //여성인원
      useRoomCnt : store.state.trnct.mobileApplyInfo.useRoomCnt, //사용객실수
      dmndCn : store.state.trnct.mobileApplyInfo.dmndCn, //요청내용
      carNo : store.state.trnct.mobileApplyInfo.carNo, //차량번호
      mblTelno : store.state.trnct.mobileApplyInfo.mblTelno, //휴대전화번호 서무직원번호
      emerCt : store.state.trnct.mobileApplyInfo.emerCt, //비상전화번호
      aplyDtl : [
        {
          aplcntRel : 'A1', //신청자 관계
          cpnnNm : store.state.auth.session.lrnerNm, //동반자 명
        },
        {
          aplcntRel : store.state.trnct.mobileApplyInfo.aplyDtl[1].aplcntRel, //신청자 관계
          cpnnNm : store.state.trnct.mobileApplyInfo.aplyDtl[1].cpnnNm, //동반자 명
        },
        {
          aplcntRel : store.state.trnct.mobileApplyInfo.aplyDtl[2].aplcntRel, //신청자 관계
          cpnnNm : store.state.trnct.mobileApplyInfo.aplyDtl[2].cpnnNm, //동반자 명
        },
        {
          aplcntRel : store.state.trnct.mobileApplyInfo.aplyDtl[3].aplcntRel, //신청자 관계
          cpnnNm : store.state.trnct.mobileApplyInfo.aplyDtl[3].cpnnNm, //동반자 명
        },
        {
          aplcntRel : store.state.trnct.mobileApplyInfo.aplyDtl[4].aplcntRel, //신청자 관계
          cpnnNm : store.state.trnct.mobileApplyInfo.aplyDtl[4].cpnnNm, //동반자 명
        }
      ],

      trnctAplySn: store.state.trnct.mobileApplyInfo.trnctAplySn,
      trnctMstSn: store.state.trnct.mobileApplyInfo.trnctMstSn,
    })

    const toggles = reactive({
      aply1 : false,
      aply2 : false,
      aply3 : false,
      aply4 : false,
      aply5 : false,
    });


    onMounted(()=> {
      getTrnctOperYmdCheck();
    })

    watch(trnctAplyIns.aplyDtl, () => {
      trnctAplyIns.aplyPeopl = trnctAplyIns.aplyDtl.filter( (v)=> {return ( v.cpnnNm != null && v.cpnnNm != '') } ).length
    });

    const getDateFormat = (format, value) => {
      if(value){
        return timestampToDateFormat(value, format ? format : 'yyyy.MM.dd');
      }
      return '-';
    };

    const fnValidation  = () =>{
    if(trnctAplyIns.emerCt == null  || trnctAplyIns.emerCt == ''){
        showMessage('비상 연락처를 입력해주세요');
        return false;
      }

      if(!isTelFormat(trnctAplyIns.emerCt) && !isHpTelFormat(trnctAplyIns.emerCt) ){
        showMessage('비상 연락처가 잘못되었습니다.');
        return false;          
      }     
      return true;      
    };

    const fnBeforeSave = () =>{
      if(!fnValidation()) return;
      setUpdateTrnctAply();
    }

    const setUpdateTrnctAply = async () => {
      if(trnctAplyIns.prsnBrcYn == 'N'){

        if(trnctAplyIns.mlePeopl == null || trnctAplyIns.mlePeopl == ''){
          trnctAplyIns.mlePeopl = 0;
        }

        if(trnctAplyIns.femPeopl == null || trnctAplyIns.femPeopl == ''){
          trnctAplyIns.femPeopl = 0;
        }
        trnctAplyIns.aplyPeopl = parseInt(trnctAplyIns.mlePeopl) + parseInt(trnctAplyIns.femPeopl);
      }
      showConfirm({
          text: '수정 하시겠습니까?',
          callback: async () => {      
            await store.dispatch(`trnct/${ACT_UPDATE_TRNCT_APLY}`, trnctAplyIns).then(res => {
              if (isSuccess(res)) {
                showMessage('수정 되었습니다.');
                router.go(-1);
              }
            }).catch((e) => {console.error(e);})
        }
      });
    }

    const deleteFamily = (index) => {
      trnctAplyIns.aplyDtl[index].aplcntRel = null;
      trnctAplyIns.aplyDtl[index].cpnnNm = null;
    }

    const goBack = () => {
      router.go(-1);
    }

    const getDayOfWeek = (_date) => {
      const week = ['일', '월', '화', '수', '목', '금', '토'];
      const dayOfWeek = week[new Date(_date).getDay()];
      return dayOfWeek;
    }

    const selectUsePeriod = (usePeriod) => {
      console.log("usePeriod",usePeriod)

      if(usePeriod.value == 0){
        usePeridToggle.value = !usePeridToggle.value;
        return;
      }

      if(usePeriod.value > trnctAplyIns.tempUsePerid){
        showMessage('일정을 변경할 수 없습니다.');
        usePeridToggle.value = !usePeridToggle.value;
        return;
      }

      trnctAplyIns.usePeriodTxt = usePeriod.label;
      trnctAplyIns.usePeriodTxt2 = usePeriod.label2;
      usePeridToggle.value = !usePeridToggle.value;
      trnctAplyIns.usePerid = usePeriod.value;
      trnctAplyIns.stbyYn = usePeriod.stbyYn;
    }

    const getTrnctOperYmdCheck = async () => {
      await store.dispatch(`trnct/${ACT_GET_TRNCT_OPER_YMD_CHECK}`,{
        trnctMstSn : store.state.trnct.mobileApplyInfo.venue,
        rsvtAplyDd : store.state.trnct.mobileApplyInfo.rsvtAplyDd,
        prsnBrcYn : isPersonal.value === true ? "Y" : "N",
        roomTy : trnctAplyIns.room
      }).then(res => {
        if(lengthCheck(res)){
          console.log('getTrnctOperYmdCheck...',res);
          usePeridOption.value = [];
          for(let i = 0 ; i < res.items.length ;i++){
            usePeridOption.value.push(res.items[i]);
          }
          //usePeridOption.value.push({label: '1박 2일 - 05/25(수요일 퇴소) -> 신청가능', value: '1'});

          let selectOption = usePeridOption.value.filter( (v) => {
            return v.value == trnctAplyIns.usePerid
          })

          if(selectOption.length != 0){
            trnctAplyIns.usePeriodTxt = selectOption[0].label;
            trnctAplyIns.usePeriodTxt2 = selectOption[0].label2;
          }
        }
      }).catch((e) => {console.error(e);})
    }

    const openSelectPeriod = () => {
      router.push('/daily/apply/step0/date');
    }

    return {
      reserveVenue,
      session,
      getDateFormat,
      usePeridOption,
      usePeridToggle,
      trnctAplyIns,
      familyList,
      toggles,
      deleteFamily,
      trnctAgree,
      goBack,
      getDayOfWeek,
      selectUsePeriod,
      getTrnctOperYmdCheck,
      setUpdateTrnctAply,
      openSelectPeriod,
      storeTrnctAplyIns,
      fnBeforeSave
    };
  },
};
</script>
<style>
select{
  background:initial !important;
}
</style>
